/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import WormholeConnect, {
  WormholeConnectConfig,
  WormholeConnectTheme,
} from "@wormhole-foundation/wormhole-connect";

import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";
import Navbar from "./components/Navbar";
const config: WormholeConnectConfig = {
  env: "mainnet",
  networks: ["ethereum", "solana", "bsc", "polygon", "base", "arbitrum"],
  tokens: [
    "ETH",
    "WETH",
    "MATIC",
    "WMATIC",
    "BNB",
    "WSOL",
    "WBNB",
    "USDC",
    "USDT",
    "BUSD",
    "DAI",
  ],
  rpcs: {
    solana:
      "https://fittest-weathered-spree.solana-mainnet.quiknode.pro/ed0422ad0fff935d83b61f06c74560d65a4c75a7/",

    ethereum: "https://damp-compatible-feather.quiknode.pro/  ",
    bsc: "https://black-alien-diagram.bsc.quiknode.pro/1e49a1bbbb8c2a726788c6324a2547c8023c29c5/",
    polygon:
      "https://wiser-methodical-waterfall.matic.quiknode.pro/324dc43d6e5462393ba378905ad601fe3232402f",

    base: "https://ultra-blissful-arrow.base-mainnet.quiknode.pro/1559d6341cf5a25b1129ffad1d62db39170c1c46/",
    arbitrum:
      "https://alien-quiet-sea.arbitrum-mainnet.quiknode.pro/3bac58e660234a01312efa28c676c5684870a15b/",
  },
};

const customTheme: WormholeConnectTheme = {
  mode: "dark",
  primary: {
    50: "#f6f7b8", // Lightest
    100: "#eff0a1",
    200: "#e8e98b",
    300: "#e1e274",
    400: "#dadb5e",
    500: "#DEDF1B", // Your chosen main color
    600: "#b8ba15",
    700: "#92950f",
    800: "#6c700a",
    900: "#464b04", // Darkest
    A100: "#f6f7b8",
    A200: "#DEDF1B",
    A400: "#b8ba15",
    A700: "#92950f",
  },
  secondary: {
    50: "#2e2e2e", // Lightest
    100: "#2b2b2b",
    200: "#282828",
    300: "#252525",
    400: "#222222",
    500: "#1C1C1C", // Your chosen main color
    600: "#191919",
    700: "#161616",
    800: "#131313",
    900: "#101010", // Darkest
    A100: "#2e2e2e",
    A200: "#1C1C1C",
    A400: "#191919",
    A700: "#161616",
  },
  divider: "#DEDF1B",
  background: {
    default: "transparent",
  },
  text: {
    primary: "#ffffff",
    secondary: "#DEDF1B",
  },
  error: red,
  info: {
    50: "#494949", // Lightest
    100: "#444444",
    200: "#3f3f3f",
    300: "#3a3a3a",
    400: "#353535",
    500: "#303029", // Your chosen main color
    600: "#2b2b24",
    700: "#26261f",
    800: "#21211a",
    900: "#1c1c15", // Darkest
    A100: "#494949",
    A200: "#303029",
    A400: "#2b2b24",
    A700: "#26261f",
  },
  success: green,
  warning: {
    50: "#494949", // Lightest
    100: "#444444",
    200: "#3f3f3f",
    300: "#3a3a3a",
    400: "#353535",
    500: "#303029", // Your chosen main color
    600: "#2b2b24",
    700: "#26261f",
    800: "#21211a",
    900: "#1c1c15", // Darkest
    A100: "#494949",
    A200: "#303029",
    A400: "#2b2b24",
    A700: "#26261f",
  },
  button: {
    primary: "#1c1c1c",
    primaryText: "black",
    disabled: "rgba(255, 255, 255, 0.1)",
    disabledText: "rgba(255, 255, 255, 0.4)",
    action: "#DEDF1B",
    actionText: "#000000",
    hover: "#DEDF1B",
  },
  options: {
    hover: "transparent",
    select: "#131313",
  },
  card: {
    background: "black",
    secondary: "#131313",
    elevation: "none",
  },
  popover: {
    background: "#1c1c1c",
    secondary: "black",
    elevation: "none",
  },
  modal: {
    background: "black",
  },
  font: {
    primary: "Inter",
    header: "Inter",
  },
};
function App() {
  return (
    <div className='  relative-positioning'>
      <div className='overlay'></div>
      <Navbar />
      <iframe
        src='https://statsfooter.versx.io/'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100%", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />{" "}
      <div className='flex md:max-w-4xl mx-auto md:px-0  px-0 justify-start    relative flex-col '>
        <h1 className=' my_heading text-left px-4  '>Cross Chain Bridge</h1>
        <div className='flex my-2 items-center px-4 md:px-4 gap-2'>
          <p className='text-white/60 text-xs'>Supported Blockchains</p>
          <div className='flex items-center gap-2 md:gap-4'>
            <img
              className='rounded-full bg-white   w-5  h-5'
              src='/chains/1.svg'
            />{" "}
            <img
              className='rounded-full bg-white p-[2px]   w-5  h-5'
              src='/chains/8.svg'
            />{" "}
            <img
              className='rounded-full bg-white p-[2px]  w-5  h-5'
              src='/chains/3.svg'
            />{" "}
            <img
              className='rounded-full bg-white p-[2px]   w-5  h-5'
              src='/chains/4.svg'
            />{" "}
            <img
              className='rounded-full bg-white p-[2px]  w-5  h-5'
              src='/chains/5.svg'
            />{" "}
            <img
              className='rounded-full bg-white p-[1px]  w-5  h-5'
              src='/chains/7.svg'
            />
          </div>
        </div>
        <div className='relative  -mt-12 z-10 wormhole_app'>
          {" "}
          <WormholeConnect config={config} theme={customTheme} />
        </div>
      </div>{" "}
      <iframe
        src='https://statsfooter.versx.io/footer'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100vh", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />{" "}
    </div>
  );
}
export default App;
